@import 'styles/variables';

.profileCard {
    padding: 0px 12px;
}

.profileCardExpanded {
    padding: 0px 17px;
}

.profileCard,
.profileCardExpanded {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    padding-bottom: 40px;

    .userRow {
        margin-top: 20px;
        margin-bottom: 40px;
        display: flex;
        flex-shrink: 0;

        .profilePic {
            height: 36px;
            min-width: 36px;
            background-color: #069ff6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .initials {
                //styleName: Title 20/Semibold;
                font-family: Montserrat;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                color: #ffffff;
            }

            .onlineBadge {
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 7.35px;
                height: 7.35px;
                border-radius: 50%;
                background-color: #007a4f;
                border: 1.4px solid #1d1d1f;
            }
        }

        .expandedControls {
            display: flex;
            overflow: hidden;

            .userData {
                padding: 0px 10px;
                margin-right: 40px;

                .userName {
                    font-family: $monserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19.5px;
                    vertical-align: center;
                    color: #d8d9dd;
                    text-overflow: clip;
                    white-space: nowrap;
                }

                .userRole {
                    font-family: $roboto;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    vertical-align: center;
                    color: #8a92a3;
                }
            }

            .dropdownButton {
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: center;

                .arrowDown {
                    color: #edecf1;
                }
            }
        }
    }

    .logOutBtn {
        cursor: pointer;
        width: 100%;
        height: 36px;
        background-color: #565961;
        border-radius: 0px;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .logOutText {
            width: 112px;
            font-family: $monserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff;
        }

        .logOutIcon {
            width: 20px;
            height: 20px;

            &--expanded {
                margin-left: 6px;
            }
        }
    }
}

.menuTitle {
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #d8d9dd;
}
